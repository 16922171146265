import React, { useState, useEffect } from "react"
import {
  Button,
  Checkbox,
  CustomProvider,
  IconButton,
  Pagination,
  Table,
} from "rsuite"
import { Link, useParams } from "react-router-dom"
import { getBotMisTrack } from "store/actions"
import { connect, useDispatch } from "react-redux"
import useWindowDimensions from "common/useWindowDimensions"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import VisibleIcon from "@rsuite/icons/Visible"
import { DownloadApiCall } from "common/ActionApiCall/DownloadApiCall"
import FileDownloadIcon from "@rsuite/icons/FileDownload"
import { ShowSwal } from "common/ShowSwal"
import moment from 'moment';

const { Column, HeaderCell, Cell } = Table

function BotMIStrackTable(props) {
    
  const [limit, setLimit] = useState(50)
  const [page, setPage] = useState(1)
  const { height, width } = useWindowDimensions()
  let checked = false
  let indeterminate = false
  const dispatch = useDispatch()
  const params = useParams()
  const data = props?.data?.BotMISReport
  const restructuredata = props?.data?.restructuredata
  const restructure_count = props?.data?.restructure_count
  const date = new Date();


  useEffect(() => {
   
    const displayStart = limit * (page - 1)

    const requestPayload = {
      token: props.token,
      replyID: params.id,
    }
    dispatch(getBotMisTrack(requestPayload))
  }, [page, limit])


  const RestrSettlementPdf = (file, fileId) => {
    const request = {
      token: props?.token,
      folder: "Settlement/restructure",
      filename: file,
      fileId: fileId,
    }
    DownloadApiCall(request, "smcb-restructure-download", "_blank")
  }

  return (
    <div className="table-responsive" style={{ fontSize: "0.9em" }}>
      <h3>Bot MIS Track</h3>
      <Table

        data={data}
        wordWrap="break-word"
        headerHeight={50}
        loading={props?.tableLoading}
      >
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            {(rowdata, dataKey) => (
              <>
                <span>
                  {page === 1 ? dataKey + 1 : limit * (page - 1) + dataKey + 1}
                </span>
              </>
            )}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Why did i get this? (Button click)</HeaderCell>
          <Cell dataKey="Whatsapp_Why_Count" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Pay now (Button click)</HeaderCell>
          <Cell dataKey="Whatsapp_PayNow_Count" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Explore alternatives (Button click)</HeaderCell>
          <Cell dataKey="Whatsapp_Explore_Alternatives_Count" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Restructure (Link click)</HeaderCell>
          <Cell dataKey="Whatsapp_Restructure_Link_Count" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Restructure (Offer selected)</HeaderCell>
          <Cell dataKey="Restructure_Option" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Reply (Link click)</HeaderCell>
          <Cell dataKey="Web_Submit_Reply_Count" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Reply submitted</HeaderCell>
          <Cell dataKey="Reply" />
        </Column>
      </Table>

      {restructure_count > 0 && (
        <>
      <h3> Settlement Letter</h3>
      <Table
        data={restructuredata}
        wordWrap="break-word"
        headerHeight={50}
        loading={props?.tableLoading}
      >
        <Column width={95} align="center">
          <HeaderCell>Sr.No.</HeaderCell>
          <Cell>
            1
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Restructure Date</HeaderCell>
          <Cell>{ moment(restructuredata[0]['created_at']).format('DD-MM-YYYY HH:mm:ss')}</Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Restructure Letter</HeaderCell>
          <Cell>
            <WhisperTooltip
              placement="top"
              trigger="hover"
              tooltipMsg="Download File"
            >
              <IconButton
                size="xs"
                appearance="primary"
                color="green"
                icon={<FileDownloadIcon />}
                onClick={() =>
                  RestrSettlementPdf(restructuredata[0]['restructureFile'], restructuredata[0]['id'])
                }
              />
            </WhisperTooltip>
          </Cell>
        </Column>
      </Table>
      </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  data: state.BotMisTrack.items,
  tableLoading: state.BotMisTrack.loading,
  token: state.Login.token,
})

export default connect(mapStateToProps)(BotMIStrackTable)
