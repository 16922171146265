//
import React, { useEffect, useRef, useState } from "react"
import { Editor } from '@tinymce/tinymce-react';
import "./index.scss"
import {SanitizeHtml} from "./sanitizeHtml";


const CustomEditor = props => {
  const [variable, setVariable] = useState([])
  const [initalData,setInitial] = useState("")
  const [testVar,setTextVar] = useState([])
  function htmlToText(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  function extractPlaceholders(inputString) {
    // Regular expression to match placeholders in the format (--placeholder--)
    const placeholderRegex = /--(.*?)--/g;
    let match;
    let placeholders = [];
    while ((match = placeholderRegex.exec(inputString)) !== null) {
        placeholders.push(match[1]);
    }

    return placeholders;
}

function findNonMatchingElements(value, variable) {
  const maxLength = Math.max(value.length, variable.length);
  const adjustedValue = value.slice(0, maxLength);
  const adjustedVariable = variable.slice(0, maxLength);
  let nonMatchingElements = [];

  for (let i = 0; i < maxLength; i++) {
      if (adjustedValue[i] !== adjustedVariable[i]) {
          nonMatchingElements.push(adjustedVariable[i]);
      }
  }
  if (nonMatchingElements.length > 0) {
    alert(`Please Check the Following Variables:\n${nonMatchingElements.join('\n ')}`);
} 

  return nonMatchingElements;
}

   function getTextVar () {
    const newtext = htmlToText(props?.request?.draft_notice_html)
     const extractedHolder  = extractPlaceholders(newtext)
  return extractedHolder
   }
  
  useEffect(() => {
 
      let variable = []
    if (props?.request.pdfGenerationStrategy === "STANDARD"){
        const splitValue = props?.request?.draft_notice_html?.split("--")
        setVariable([])
        splitValue?.map((value, key) => {
          if (key % 2 !== 0) {
            setVariable(prev => [...prev, value])
            variable.push(value)     
              }
        })
    }
    else {
        const regex = /\${(.*?)}/g;
        let match;
        const values = [];
        
        while ((match = regex.exec(props?.request?.draft_notice_html)) !== null) {
            values.push(match[1]); // match[1] contains the value inside ${}
        }
        setVariable(values)
      
    }

  }, [props?.request?.draft_notice_html])


  const handleEditorChange = (content, editor) => {
      props?.setRequest(prev => ({
        ...prev,
        draft_notice_html:SanitizeHtml(content),
      }))
   }
  

   useEffect(()=>{
    const value = getTextVar()
    let variable = []
    setInitial(SanitizeHtml(props?.request?.draft_notice_html))
     if(props?.request.pdfGenerationStrategy === "STANDARD") {
      const splitValue = props?.request?.draft_notice_html?.split("--")
      splitValue?.map((value, key) => {
        if (key % 2 !== 0) {
          setVariable(prev => [...prev, value])
          variable.push(value)     
        }})
      findNonMatchingElements(variable,value)
     }
     else {
      const regex = /\${(.*?)}/g;
      let match;
      const values = [];
      const expresvariable =[]
        const text  = htmlToText(props?.request?.draft_notice_html)
          
      while ((match = regex.exec(text)) !== null) {
          values.push(match[1]); // match[1] contains the value inside ${}
      }
      while ((match = regex.exec(props?.request?.draft_notice_html)) !== null) {
        expresvariable.push(match[1]); // match[1] contains the value inside ${}
    }

         let missingValue = []
          for (let eleValue in values){
             console.log(values[eleValue],expresvariable[eleValue])
                    if(values[eleValue] !== expresvariable[eleValue] ){
                        missingValue.push(values[eleValue])
                         console.log("working")
                    }
               }
                 missingValue.length !== 0  && alert(`Missing this variable:\n${missingValue.join('\n')}`)
           
     }
   
   },[])

  return (
    <div className="row">
      <div className={props?.editType === true ? "col-md-9 col-sm-12" : ""}>
      <Editor
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      init={{
        height: props?.editType === true ? "100%" : "600px",
        selector: 'textarea#editable',
        plugins: "a11ychecker advcode advlist advtable anchor autocorrect autolink autosave casechange charmap checklist code codesample directionality editimage emoticons export footnotes formatpainter fullscreen help image importcss inlinecss insertdatetime link linkchecker lists media mediaembed mentions mergetags nonbreaking pagebreak pageembed permanentpen powerpaste preview quickbars save searchreplace table tableofcontents template tinycomments tinydrive tinymcespellchecker typography visualblocks visualchars wordcount",
        toolbar: 'undo redo customBreak | code |blocks fontfamily fontsize | bold italic underline strikethrough | numlist bullist indent outdent | fullscreen | align lineheight | link image media table | emoticons charmap | removeformat | insertdatetime | ltr rtl |',
        font_family_formats: 'Arial=arial,helvetica,sans-serif;Times New Roman=times new roman,times,serif;Kruti Dev 010=Kruti Dev 010,arial,sans-serif;Poppins=Poppins,helvetica,sans-serif;Helvetica=helvetica,sans-serif;DevLys 010=DevLys 010,arial,sans-serif;DevLys 040=DevLys 040,arial,sans-serif',
        setup: (editor) => {
          editor.ui.registry.addButton('customBreak', {
            text: 'Break',
            onAction: () => {
              editor.insertContent('<p style="page-break-before: always;">&nbsp;<!-- pagebreak --></p>');
            }
          }) }

      }}
      initialValue={SanitizeHtml(initalData)}
      onEditorChange={handleEditorChange}
    />
      </div>
      {props?.editType === true && (
        <div className="col-md-3 col-sm-12 show-variable">
          <h4>Variable</h4>
          <div className="contentVariable">
            {/*  */}
            {variable?.map((value, index) => {
              return <p key={index}>{value}</p>
            })}
          </div>
        </div>
      )}
    </div>
  )
}
export default CustomEditor
