import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import {
  getExistingNoticDetailsById,
  setBreadcrumbItems,
} from "../../../store/actions"
import NoticeForm from "./NoticeForm"

const EditNotice = props => {
  const [request, setRequest] = useState([])
  const dispatch = useDispatch()
  const params = useParams()

  const breadcrumbItems = [
    { title: "Incase360", link: "#" },
    { title: "Existing Notice", link: "/admin/existingnotice" },
    { title: "Edit Notice", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Edit Notice", breadcrumbItems)
  })

  useEffect(() => {
    const requestPayload = {
      token: props?.token,
      noticeId: atob(params.id),
    }
    dispatch(
      getExistingNoticDetailsById(requestPayload, result => {
        console.log(result)
        const getRequestData = {
          notice_id: result?.data?.responseData?.notice_detail?.notice_id,
          notice_type: result?.data?.responseData?.notice_detail?.notice_type,
          draft_notice_html:
            result?.data?.responseData?.notice_detail?.draft_notice_html,
          willLinkExpire:
            result?.data?.responseData?.notice_detail?.willLinkExpire,
          send_as: result?.data?.responseData?.notice_detail?.send_as,
          token: props?.token,
          notice_desc: result?.data?.responseData?.notice_detail?.notice_desc,
          bulk_csv: null,
          notice_bulk: result?.data?.responseData?.notice_detail?.notice_bulk,
          autoGeneratePdf:
            result?.data?.responseData?.notice_detail?.autoGeneratePdf,
          canvaSubtitle:
            result?.data?.responseData?.notice_detail?.canvaSubtitle,
            enterpriseAbbr:
            result?.data?.responseData?.notice_detail?.enterprise_abbr,
            whatsappTemplatePrefix:
            result?.data?.responseData?.notice_detail?.whatsapp_template_prefix,
            sent_whatsapp_text:
            result?.data?.responseData?.notice_detail?.sent_whatsapp_text,
            sent_whatsapp_pdf:
            result?.data?.responseData?.notice_detail?.sent_whatsapp_pdf,
            use_organization_name:
            result?.data?.responseData?.notice_detail?.use_organization_name,
            whatsappTextVariable:
            result?.data?.responseData?.notice_detail?.whatsappTextVariable,
            whatsappPdfVariable:
            result?.data?.responseData?.notice_detail?.whatsappPdfVariable,
          emailEnabled:
            result?.data?.responseData?.notice_detail?.email_enabled,
          smsEnabled: result?.data?.responseData?.notice_detail?.sms_enabled,
          whatsappEnabled:
            result?.data?.responseData?.notice_detail?.whatsapp_enabled,
          rpadEnabled: result?.data?.responseData?.notice_detail?.rpad_enabled,
          natureOfNotice:
            result?.data?.responseData?.notice_detail?.nature_of_notice,
            pdfGenerationStrategy:result?.data?.responseData?.notice_detail?.pdf_generation_strategy,
          assignedUsers: result?.data?.responseData?.assignedUsers
            ?.map(user => user.user_id)
            .join(","),
          assignedNotices: result?.data?.responseData?.assignedNotices,
          transmission_type:result?.data?.responseData?.notice_detail?.transmission_type,
          letterhead_configuration:result?.data?.responseData?.notice_detail?.letterhead_configuration,
          fontUsed:result?.data?.responseData?.notice_detail?.fontUsed,
          email_template_config:result?.data?.responseData?.notice_detail?.email_template_config,
        }
        setRequest(getRequestData)
      })
    )
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Notice - Admin | Incase360</title>
      </MetaTags>

      {/* {request?.draft_notice_html && ( */}
      <NoticeForm
        existData={props?.rowCount !== null}
        request={request}
        setRequest={setRequest}
        loading={props?.loading}
      />
      {/* )} */}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  data: state.ExistingNoticeDetailsById.items,
  loading: state.ExistingNoticeDetailsById.loading,
  rowCount: state.ExistingNoticeDetailsById.rowCount,
  token: state.Login.token,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(EditNotice)
